<template>
    <div class="table">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column label="时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.createTime,
                        })
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="公司名称" show-overflow-tooltip width="239">
                <template slot="header">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司名称</div>
                </template>
                <template slot-scope="scope">
                    <el-button type="text" style="color: #2370eb" @click="onDetails(scope.row)">
                        <i
                            :class="
                                scope.row.topStatus == 1
                                    ? 'iconfont icon-shoucang'
                                    : 'iconfont icon-star'
                            "
                            v-if="btnP.topping"
                            @click.stop="onTopping(scope.row)"
                            style="margin-right: 5px"
                        ></i
                        >{{ scope.row.companyName }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="类型" show-overflow-tooltip width="61">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.enterpriseType == 1
                            ? '企业'
                            : scope.row.enterpriseType == 2
                            ? '个人'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="域名" prop="companyDomain" width="155" show-overflow-tooltip>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="联系方式" width="105" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top"
                        v-if="
                            scope.row.customerMainContactList &&
                            scope.row.customerMainContactList.length > 0
                        "
                    >
                        <div slot="content">
                            <p
                                v-for="(item, index) in scope.row.customerMainContactList"
                                :key="index"
                            >
                                {{
                                    $tableDataHandle.contactWay(item) + ' ' + item.contactContent ||
                                    '- -'
                                }}
                            </p>
                        </div>
                        <span
                            v-if="
                                scope.row.customerMainContactList &&
                                scope.row.customerMainContactList.length > 0
                            "
                        >
                            <i
                                :class="
                                    $tableDataHandle.contactWayColor(
                                        scope.row.customerMainContactList[0],
                                    ).icon
                                "
                                :style="{
                                    color: $tableDataHandle.contactWayColor(
                                        scope.row.customerMainContactList[0],
                                    ).color,
                                }"
                            >
                            </i>
                            {{ scope.row.customerMainContactList[0].contactContent }}</span
                        >
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="品牌"
                width="105"
                :formatter="primaryEmailBrand"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="标签" width="105">
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        placement="top-start"
                        v-if="scope.row.tagList && scope.row.tagList.length > 0"
                    >
                        <div slot="content">
                            <span v-if="scope.row.tagList.length > 0">
                                <span v-for="item in scope.row.tagList"
                                    >{{ item.tagName }}&nbsp;&nbsp;</span
                                >
                            </span>
                        </div>
                        <span v-if="scope.row.tagList.length > 0">
                            <span class="Times" v-for="item in scope.row.tagList">{{
                                item.tagName
                            }}</span>
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                width="103"
                label="到期时间"
                :width="$tableDataHandle.getTextWidth('2022-12-1')"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.customerExpireTime">{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.customerExpireTime,
                        })
                    }}</span>
                    <span></span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip width="71">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1 ? '正常' : scope.row.status == 2 ? '删除' : '- -'
                    }}</span>
                </template>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { customerList } from '@/api/customer/customer.js';
import { customerTop, editCustomer } from '@/api/user/ae/customer';
import { addClue } from '@/api/user/clue.js';
import { clueAdd } from '@/api/clue/meClue.js';
import { getCustomerId } from '@/api/user/ae/customer.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
            direction: 'rtl',
            drawerAddClue: false,
            drawerTitle: '',
            pageData: {},
        };
    },
    methods: {
        getData(searchVal, btnP) {
            this.pageData = searchVal;
            console.log(searchVal);
            customerList(searchVal).then((res) => {
                this.tableData = res.data.list;
                this.$emit('totalNub', res.data.total);
                this.btnP = btnP;
            });
        },
        // 置顶
        onTopping(row) {
            let data = {
                param: {
                    id: row.customerAdminId,
                },
            };
            if (row.topStatus == 0) {
                data.param.topStatus = 1;
            } else {
                data.param.topStatus = 0;
            }
            customerTop(data).then((res) => {
                if (row.topStatus == 0) {
                    if (res.code == 200) {
                        this.$message.success('标记成功');
                    }
                } else {
                    if (res.code == 200) {
                        this.$message.success('取消成功');
                    }
                }

                this.$emit('getData');
            });
        },

        onDetails(row) {
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            for (let i in btn) {
                if (btn[i].children) {
                    for (let key in btn[i].children) {
                        if (btn[i].children[key].route == 'edit') {
                            type = true;
                            break;
                        }
                    }
                    break;
                }
                if (btn[i].route == 'edit') {
                    type = true;
                    break;
                }
            }
            if (type) {
                this.$router.push({
                    path: '/customerdetails',
                    query: {
                        type: type,
                    },
                });
            } else {
                this.$router.push('/customerdetails');
            }
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getType', '2');
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },

        // 置顶
        onStarMark(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            if (row.mark == 1) {
                data.param.mark = 0;
            } else if (row.mark == 0) {
                data.param.mark = 1;
            }
            libraryUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getTableData();
                }
            });
        },

        // 原邮箱品牌
        primaryEmailBrand(row) {
            return this.$tableDataHandle.primaryEmailBrand(row);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/ th:nth-child(3) .cell {
    padding-left: 30px;
}
.table {
    width: 100%;
    height: 100%;
}
.icon-shoucang {
    color: #feb516;
}
.icon-star {
    color: #ccc;
}
.Times {
    height: 24px;
    background: #fff2ed;
    border-radius: 2px;
    border: 1px solid #ff7a44;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ff7a45;
}
</style>
