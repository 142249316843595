var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('el-table',{ref:"multipleTable",staticClass:"table-box",attrs:{"data":_vm.tableData,"height":"100%","header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '500',
        }}},[_c('el-table-column',{attrs:{"width":"10"}}),_c('el-table-column',{attrs:{"label":"时间","show-overflow-tooltip":"","width":"93"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.$tableDataHandle.createTime({ createTime: scope.row.createTime, })))])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"公司名称","show-overflow-tooltip":"","width":"239"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDetails(scope.row)}}},[(_vm.btnP.topping)?_c('i',{class:scope.row.topStatus == 1
                                ? 'iconfont icon-shoucang'
                                : 'iconfont icon-star',staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.stopPropagation();return _vm.onTopping(scope.row)}}}):_vm._e(),_vm._v(_vm._s(scope.row.companyName))])]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("     公司名称")])])],2),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"类型","show-overflow-tooltip":"","width":"61"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.enterpriseType == 1 ? '企业' : scope.row.enterpriseType == 2 ? '个人' : '- -'))])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"域名","prop":"companyDomain","width":"155","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"联系方式","width":"105","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.customerMainContactList &&
                        scope.row.customerMainContactList.length > 0
                    )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row.customerMainContactList),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.contactWay(item) + ' ' + item.contactContent || '- -')+" ")])}),0),(
                            scope.row.customerMainContactList &&
                            scope.row.customerMainContactList.length > 0
                        )?_c('span',[_c('i',{class:_vm.$tableDataHandle.contactWayColor(
                                    scope.row.customerMainContactList[0]
                                ).icon,style:({
                                color: _vm.$tableDataHandle.contactWayColor(
                                    scope.row.customerMainContactList[0]
                                ).color,
                            })}),_vm._v(" "+_vm._s(scope.row.customerMainContactList[0].contactContent))]):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"品牌","width":"105","formatter":_vm.primaryEmailBrand,"show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"标签","width":"105"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.tagList && scope.row.tagList.length > 0)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(scope.row.tagList.length > 0)?_c('span',_vm._l((scope.row.tagList),function(item){return _c('span',[_vm._v(_vm._s(item.tagName)+"  ")])}),0):_vm._e()]),(scope.row.tagList.length > 0)?_c('span',_vm._l((scope.row.tagList),function(item){return _c('span',{staticClass:"Times"},[_vm._v(_vm._s(item.tagName))])}),0):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"width":"103","label":"到期时间","width":_vm.$tableDataHandle.getTextWidth('2022-12-1'),"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.customerExpireTime)?_c('span',[_vm._v(_vm._s(_vm.$tableDataHandle.createTime({ createTime: scope.row.customerExpireTime, })))]):_vm._e(),_c('span')]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"状态","show-overflow-tooltip":"","width":"71"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.status == 1 ? '正常' : scope.row.status == 2 ? '删除' : '- -'))])]}}])}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }