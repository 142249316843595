<template>
    <div class="customer-box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>原邮箱品牌</span>
                    <el-select
                        v-model="searchVal.primaryEmailBrand"
                        placeholder="请选择"
                        size="small"
                        style="width: 180px"
                        clearable
                        @change="(pagesize = 10), (currentPage = 1), getData()"
                    >
                        <el-option
                            v-for="item in primaryEmailBrandList"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        v-model="searchVal.timeType"
                        size="small"
                        style="width: 100px; margin: 0 13px"
                        placeholder="请选择"
                    >
                        <el-option label="到期时间" :value="1"></el-option>
                    </el-select>

                    <el-date-picker
                        size="small"
                        v-model="searchVal.inpTime"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <span>查询</span>

                    <el-input
                        size="small"
                        style="width: 150px"
                        placeholder="公司名称、域名"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(pagesize = 10), (currentPage = 1), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="(pagesize = 10), (currentPage = 1), getData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 13px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>

            <div class="search-two">
                <p>
                    <Department
                        ref="department"
                        :departmentWidth="195"
                        :adminWidth="180"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.searchUser"
                        @searchData="searchData"
                    ></Department>
                </p>
                <!-- <p v-if="btnP.depSearch">
                    <span>部门</span>
                    <el-select
                        v-model="searchVal.departmentId"
                        size="small"
                        style="width: 195px"
                        placeholder="请选择"
                        @change="changeDepartmentId()"
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p>
                <p v-if="btnP.searchUser">
                    <span>成员</span>
                    <el-select
                        v-model="searchVal.adminId"
                        size="small"
                        style="width: 180px"
                        placeholder="请选择"
                        @change="getData()"
                    >
                        <el-option
                            v-for="item in userName"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </p> -->

                <p>
                    <span>选择标签</span>
                    <el-button
                        @click="onChoiceLabel"
                        type="primary"
                        size="small"
                        style="
                            border-color: #e5e5e5;
                            background: none;
                            color: #333333;
                        "
                        >请选择
                        <i
                            class="el-icon-arrow-right"
                            style="color: #2162cb"
                        ></i>
                    </el-button>
                </p>
                <div class="labelList">
                    <p
                        :class="item.category == 0 ? 'blue' : 'yellow'"
                        v-for="item in $searchLabel.state.labelList"
                        :key="item.id"
                    >
                        {{ item.tagName }}
                        <span class="cancels" @click="onDeleteLabel(item)">
                            <span class="el-icon-close"></span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>客户列表</p>
            <span>
                <button
                    v-if="$route.path == '/latent'"
                    @click="addData()"
                    class="cancel"
                >
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    新增客户
                </button>
            </span>
        </div>
        <div class="table">
            <Table ref="table" @totalNub="totalNub" @getData="getData" />
        </div>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 标签搜索 -->
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
        <!-- 新增客户 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleAdd"
            size="880px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增客户
                </div>
            </template>
            <AddCustomer @close="handleAdd" ref="detail" />
        </el-drawer>
    </div>
</template>
<script>
import ChoiceLabel from '../label/searchLabel/searchLabel.vue';
import AddLable from '../label/addLabel/addLabel';
import Table from './table.vue';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../components/Department';
export default {
    components: {
        ChoiceLabel,
        AddLable,
        Table,
        Department,
    },
    inject: ['reload'],
    data() {
        return {
            searchVal: {
                name: '',
                departmentId: Number(sessionStorage.getItem('departmentId')),
                adminId: Number(sessionStorage.getItem('adminId')),
                primaryEmailBrand: '',
                timeType: 1,
                inpTime: [],
            },
            btnP: {},
            userName: [],
            dempData: [],
            primaryEmailBrandList: this.$searchForm.mailboxbrand(),
            pagesize: 10,
            total: 0,
            currentPage: 1,
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            drawerAdd: false,
            direction: 'rtl',
            isPage: false,
        };
    },

    watch: {
        $route(to, from) {
            //
            if (
                (from.path == '/latent' && to.path == '/deali') ||
                (from.path == '/deali' && to.path == '/latent')
            ) {
                this.$choiceLabel.commit('emptyData');
            }
            this.reload();
        },
    },
    created() {
        window.addEventListener('keydown', this.keyDown, true);
    },
    mounted() {
        console.log(JSON.parse(sessionStorage.getItem('pageData')));
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param;
            this.$refs.department.$data.departmentId =
                JSON.parse(sessionStorage.getItem('pageData')).param
                    .departmentId || undefined;
            this.$refs.department.$data.adminId =
                JSON.parse(sessionStorage.getItem('pageData')).param.adminId ||
                '';
            if (JSON.parse(sessionStorage.getItem('pageData')).param.tagList) {
                this.$searchLabel.commit(
                    'getLabelList',
                    JSON.stringify(
                        JSON.parse(sessionStorage.getItem('pageData')).param
                            .tagList
                    )
                );
            }
            this.$refs.department.getUserList(
                JSON.parse(sessionStorage.getItem('pageData')).param
                    .departmentId
            );
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.$refs.department.$data.departmentId =
                this.searchVal.departmentId || undefined;
            this.$refs.department.$data.adminId = this.searchVal.adminId || '';
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            if (btn.depSearch) {
                this.getDempData();
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (!btn.depSearch && btn.searchUser) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                if (this.searchVal.departmentId == '') {
                    this.searchVal.departmentId = Number(
                        sessionStorage.getItem('departmentId')
                    );
                }
                this.$refs.department.getData(this.btnP, res.data);
            });
        },

        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        getData() {
            let data = {
                param: {
                    name: this.searchVal.name,
                    primaryEmailBrand: this.searchVal.primaryEmailBrand,
                    timeType: this.searchVal.timeType,
                    inpTime: this.searchVal.inpTime,
                    status: 2,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.btnP.searchUser) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            if (!data.param.adminId) {
                this.$refs.table.$data.tableData = [];
                return;
            }
            if (this.searchVal.timeType == 1) {
                if (
                    this.searchVal.inpTime &&
                    this.searchVal.inpTime.length > 0
                ) {
                    data.param.startExpireDate = this.searchVal.inpTime[0];
                    data.param.endExpireDate = this.searchVal.inpTime[1];
                }
            }

            let labelArr = this.$searchLabel.state.labelList;
            if (labelArr && labelArr.length > 0) {
                data.param.tagList = labelArr;
            }
            this.$refs.table.getData(data, this.btnP);
        },
        totalNub(i) {
            this.total = i;
        },
        // 时间输入框变化
        expireTime() {
            this.searchVal.name = '';
            this.currentPage = 1;
            this.pagesize = 10;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 新增客户
        addData() {
            this.drawerAdd = true;
        },
        handleAdd() {
            this.drawerAdd = false;
            this.$choiceLabel.commit('emptyData');
            this.getData();
        },
        // 标签查询
        onChoiceLabel() {
            this.dialogChoice = true;
            setTimeout(() => {
                this.isGet = !this.isGet;
            }, 100);
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
            this.getData();
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$searchLabel.commit('removeLabel', JSON.stringify(item));
            this.getData();
        },
        handleClose() {
            this.dialogChoice = false;
            this.getData();
        },
        searchEmpty() {
            this.searchVal = {};
            this.getData();
            this.$searchLabel.commit('emptyData');
        },
    },
    destroyed() {
        this.$searchLabel.commit('emptyData');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.search-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    span {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin: 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
            }
        }
        .right {
            background: #2370eb;
            border-radius: 2px;
            border-color: #2370eb;
            margin-right: 16px;
        }
    }
    .search-two {
        display: flex;
        p {
            padding-bottom: 10px;
        }
    }
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    .add {
        border-color: #d9d9d9;
        color: #333333;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
    }

    button:hover {
        background: #2370eb;
        color: #ffffff;
        border-color: #2370eb;
    }
    .cancel:hover {
        background: #fff;
        color: #666;
        border-color: #e5e5e5;
    }
}
.table {
    flex: 1;
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelList {
    display: inline-block;
    .cancels {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        color: #fff;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -26px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -28px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
    p {
        position: relative;
        display: inline-block;
        padding: 2px 8px;
        padding-bottom: 2px !important;
        border-radius: 2px;
        margin-top: 3px;
        border: 1px solid #2370eb;
        margin-left: 16px;
        text-align: center;
        font-size: 14px;
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancels {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancels {
            border-left: 10px solid #fa8c15;
        }
    }
}
</style>
